.question_card {
    width: 100%;
    height: auto;
    border-radius: 6px;
    text-align: left;
    background-color: white;
    // border: 1px solid #dedede;
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    // padding: 20px;
    user-select: none;
    margin-bottom: 15px;
    transition: opacity 0.5s cubic-bezier(0.445, 0.05, 0.55, 0.95);

    &[data-inactive="true"] {
        opacity: 0.5;
        user-select: none;
        pointer-events: none;
        // cursor:not-allowed;
    }
    &:last-child {
        margin-bottom: 0;
    }

    .question_card_title {
        font-size: 1rem;
        font-weight: bold;
        padding: 20px;
        // margin-bottom: 10px;
    }

    .question_card_content {
        padding: 20px;
        padding-top: 0px;
        padding-bottom: 20px;
        // width: 100%;
    }
}

.line_btn {
    line-height: 3;
    border-top: 1px solid #dedede;
    text-align: center;

    cursor: pointer;

    &[data-last] {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
    }

    &:hover {
        background-color: rgb(245, 245, 245);
    }

    &[data-selected="true"] {
        background-color: var(--color-success);
        color: white;
    }

    &.after_process[data-selected="true"] {
        background-color: var(--color-warning);
        color: white;
    }

    &.invalid[data-selected="true"] {
        background-color: var(--color-danger);
        color: white;
    }
}

.tags {
    margin: 0;
    padding: 0;
    list-style: none;
    display: block;
    // width: 100%;
    box-sizing: border-box;
    .tag {
        list-style: none;
        display: inline-flex;
        padding: 6px 10px;
        border-radius: 6px;
        margin: 2px 4px;
        border: 1px solid #e8e8e8;
        font-size: 0.8rem;
        color: #191919;
        cursor: pointer;

        flex-direction: row;
        align-items: center;
        &:hover {
            background-color: rgb(245, 245, 245);
        }
        &[data-selected="true"] {
            background-color: var(--color-primary);
            color: white;

            .tag_info {
                color:white;
            }
        }

        .tag_text {
            user-select: none;
        }

        .tag_info {
            margin-left: 10px;
            color: #999;
        }
    }
}
