.loader_wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 3rem;
    display: flex;
    background-color: rgba(255, 255, 255, .9);
    justify-content: center;
    align-items: center;
    color: var(--color-primary);
    z-index: 9999;
}