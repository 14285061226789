.item {
    width: 100%;
    position: relative;
    height: auto;
    box-sizing: border-box;
    padding: 12px 20px;
    // border: 1px solid #c2c2c2;
    /* outline: 1px solid #292929; */
    margin-bottom: 20px;
    // border: none;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
    // optimize for performance
    content-visibility: auto;
    contain-intrinsic-size: 64px;

    &:hover {
        background-color: #f3f3f3;
        // border-bottom: 1px solid #e0e0e0;
        // filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, .10));
    }

    .badge {
        position: absolute;
        top: 0px;
        right: 0px;
        // border-radius: 6px;
        border-bottom-left-radius: 6px;
        width: 16px;
        height: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 10px;
        // font-weight: bold;
        color: white;
        box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.1);

        &[data-finished="true"] {
            background-color: #4caf50;
        }

        &[data-finished="false"] {
            background-color: #ff4800;
        }
    }
    .item_left {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        .item_firstline {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: baseline;
            text-overflow: clip;
            // margin-bottom: 8px;
            .item_firstline_title {
                font-size: 1.4rem;
                font-weight: bold;
                // margin-bottom: 4px;
                margin-right: 8px;
                max-width: 250px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space:nowrap
            }
            .item_firstline_id {
                font-size: 8px;
                border-radius: 4px;
                // width: 40px;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 100px;
                background-color: rgb(94, 94, 94);
                color: white;
                padding: 2px 4px;
                box-sizing: border-box;
                font-weight: 400;
                overflow: hidden;
            }
        }
        .item_secondline {
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-start;
            align-items: baseline;

            .item_secondline_check_wrapper {
                border-radius: 4px;
                padding: 4px;
                // padding-top: 0px;
                box-sizing: border-box;
                border: 1px solid rgb(214, 214, 214);
                display: flex;
                flex-direction: row;
                margin-right: 8px;
                // margin-top: 8px;
                &:last-child {
                    margin-right: 0;
                }

                .check {
                    border-radius: 50%;
                    background-color: white;
                    width: 8px;
                    height: 8px;
                    // border: 2px solid #aaa;
                    box-shadow: 0px 0px 0px 2px #aaa;
                    outline: 1px solid white;
                    margin-right: 8px;

                    &[data-checked="true"] {
                        background-color: var(--color-success);
                        // box-shadow: 0px 0px 0px 2px #00b300;
                    }

                    &.after_process[data-checked="true"]{
                        background-color: var(--color-warning);
                    }

                    &.check_invalid[data-checked="true"]{
                        background-color: var(--color-danger);
                    }

                    &:last-child{
                        margin-right: 0;
                    }
                }
            }
        }
    }
    .item_right {
        /* border: 2px solid #BEBEBE; */

        img {
            display: block;
            border-radius: 6px;
            background-color: #f3f3f3;
            /* filter: blur(2px); */
            // filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, .2));
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
            object-fit: cover;
            content-visibility: auto;
        }
    }
}
